<template>
  <div class="site-product-container">
    <div class="search-form-container">
      <el-form ref="searchForm" inline label-width="100px">
        <el-form-item label="供应商ID:">{{ storeDetailObj.storeId }}</el-form-item>
        <el-form-item label="供应商名称:">{{ storeDetailObj.storeName }}</el-form-item>
        <el-form-item label="已添加样片数量:" label-width="150px">
          <span style="color: #2861e2">{{ statisticData.totalNum || 0 }}</span>
          个
        </el-form-item>
        <el-form-item label="可拍样片数量:" label-width="150px">
          <span style="color: #2861e2">{{ statisticData.onShelfNum || 0 }}</span>
          个
        </el-form-item>
      </el-form>
    </div>
    <div class="hr"></div>
    <div class="operate-container flex product-site-operate">
      <div class="title">
        <span class="title-mark"></span>
        <span class="title-text">样片设置</span>
      </div>
      <el-button class="" type="primary" @click="linkSample">上架样片</el-button>
    </div>
    <div style="padding: 0 10px">
      <LTable :tableLoading="sampleLoading" :tableData="sampleData" tableHeight="540" :tableHeader="sampleHeader" :getTableList="getSampleList">
        <template slot="sampleTag" slot-scope="scope">
          <div class="table-tag">
            <!-- <label for="">标签：</label> -->
            <el-tag v-for="(tag, index) in scope.row.sampleTags" :key="index" style="margin-right: 10px; margin-bottom: 10px" :style="`color:#000;border:0;background-color:${tag.colour}`">
              #{{ tag }}
            </el-tag>
          </div>
        </template>

        <template slot="sampleName" slot-scope="scope">
          <el-button type="text" @click="gotoSample(scope.row)">{{ scope.row.sampleName }}</el-button>
        </template>
        <template slot="pic" slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="showUrl(scope.row.coverPictureUrl, 'x-oss-process=image/resize,m_fixed,h_50,w_50')"
            fit="cover"
            :preview-src-list="[scope.row.coverPictureUrl]"
          ></el-image>
        </template>
        <template slot="sampleCity" slot-scope="scope">
          <span v-for="(city, index) in scope.row.sampleCity" :key="index">{{ scope.row.sampleCity.length - 1 === index ? city : city + '，' }}</span>
        </template>
        <template slot="h5Number" slot-scope="scope">
          <el-button type="text">{{ scope.row.h5Number || 0 }}</el-button>
        </template>
        <template slot="photoTotalNumber" slot-scope="scope">
          <el-button type="text">{{ scope.row.photoTotalNumber || 0 }}</el-button>
        </template>
        <template slot="storeRelevancy" slot-scope="scope">
          <template v-if="scope.row.storeRelevancy && scope.row.storeRelevancy.length">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                <template v-for="(item, index) in scope.row.storeRelevancy">
                  <div :key="index" class="content">{{ item.storeName }}</div>
                </template>
              </div>
              <el-button type="text">{{ scope.row.storeRelevancy.length }}</el-button>
            </el-tooltip>
          </template>
          <div v-else>0</div>
        </template>

        <template slot="isDisable" slot-scope="scope">
          <el-tag :type="scope.row.isDisable === 0 ? 'info' : ''">
            {{ scope.row.isDisable === 0 ? '停用' : '启用' }}
          </el-tag>
        </template>
        <template slot="isOnShelf" slot-scope="scope">
          <el-tag :type="scope.row.isOnShelf === 0 ? 'info' : ''">
            {{ scope.row.isOnShelf === 0 ? '下架' : '上架' }}
          </el-tag>
        </template>
        <!-- <template slot="operate" slot-scope="scope">
          <el-button type="text" @click="onDeleteSample(scope.row)">删除</el-button>
        </template> -->
        <template slot="operate" slot-scope="scope">
          <el-button type="text" @click="onUpdateSample(scope.row)">{{ scope.row.isOnShelf === 1 ? '下架' : '上架' }}</el-button>
          <!-- <el-button type="text" @click="onDeleteSample(scope.row)">删除</el-button> -->
        </template>
      </LTable>
    </div>

    <SelectedSample :sampleVisible.sync="sampleVisible" :searchForm="searchForm" :cityId="cityId" :selection="sampleData" @change="onSampleChange" />
  </div>
</template>

<script>
import { getStoreDetail, deleteSampleByIds, getStoreBySampleList, saveSample, findSampleStatisticNumByStoreId, updateOnShelf } from '@/api/store';
import { showUrl } from '@/utils';
export default {
  data() {
    return {
      id: undefined,
      storeDetailObj: {},
      sampleLoading: false,
      sampleHeader: [
        { label: '封面图', prop: 'pic', align: 'center', width: '100', render: true },
        { label: '样片ID', prop: 'sampleCode', width: '120', align: 'center' },
        { label: '样片名称', prop: 'sampleName', minWidth: '160', render: true },
        { label: '已选次数', prop: 'h5Number', align: 'center', minWidth: '100', render: true, sortable: true },
        { label: '实拍次数', prop: 'photoTotalNumber', align: 'center', minWidth: '100', render: true, sortable: true },
        { label: '可拍门店', prop: 'storeRelevancy', align: 'center', minWidth: '100', render: true },
        { label: '样片类型', prop: 'categoryIdName', align: 'center', width: '120' },
        { label: '标签', prop: 'sampleTag', align: 'center', render: true, minWidth: '160' },
        { label: '所属城市', prop: 'sampleCity', align: 'center', width: '160', render: true },
        { label: '状态', prop: 'isDisable', align: 'center', width: '100', render: true, fixed: 'right' },
        { label: '门店上架状态', prop: 'isOnShelf', align: 'center', width: '100', render: true, fixed: 'right' },
        { label: '操作', prop: 'operate', align: 'center', width: '100', render: true, fixed: 'right' },
      ],
      sampleData: [],
      sampleVisible: false,
      cityId: '',
      statisticData: {},
      searchForm: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.cityId = this.$route.query.cityId;
    this.getData();
    this.getSampleStatisticNumByStoreId();
  },
  methods: {
    showUrl,
    gotoSample(row) {
      const url = `${window.location.origin}/index/product/sample/list/detail?sampleId=${row.sampleId}`;
      window.open(url);
    },
    getSampleStatisticNumByStoreId() {
      findSampleStatisticNumByStoreId({
        data: this.id,
      }).then((res) => {
        this.statisticData = res.data;
      });
    },
    async getData() {
      try {
        let responseDetail = (await getStoreDetail({ data: { storeId: [this.id] } })).data || [];
        this.storeDetailObj = responseDetail[0];
        this.getSampleList();
      } catch (error) {
        console.log(error);
      }
    },
    async getSampleList() {
      try {
        this.sampleLoading = true;

        this.sampleData = (await getStoreBySampleList({ data: { storeId: this.id } })).data || [];
      } catch (error) {
        console.log(error);
      }
      this.sampleLoading = false;
    },
    linkSample() {
      this.searchForm.ignoreSampleIds = this.sampleData.map((e) => {
        return e.sampleId;
      });
      this.sampleVisible = true;
    },
    onSampleChange(e) {
      e = e.map((el) => {
        return {
          storeId: this.id,
          sampleId: el.sampleId,
        };
      });
      saveSample({ data: e }).then(() => {
        this.getSampleList();
      });
    },
    onUpdateSample(row) {
      updateOnShelf({
        data: {
          storeSampleId: row.storeSampleId,
          isOnShelf: row.isOnShelf === 1 ? 0 : 1,
        },
      })
        .then((res) => {
          console.log(res);
          this.$message.success(row.isOnShelf === 1 ? '样片下架成功~' : '样片上架成功~');
          this.getSampleList();
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(row.isOnShelf === 1 ? '样片下架失败~' : '样片上架失败~');
        });
    },
    async onDeleteSample(row) {
      this.$confirm('是否确认删除当前产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteSampleByIds({ data: [row.storeSampleId] });
          this.$notify.success({ title: '成功', message: '删除成功', duration: 2000 });
          this.getSampleList();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.site-product-container {
  background-color: #fff;
  .product-site-operate {
    justify-content: space-between;
  }
  .operate-container {
    padding: 11px 6px;
  }
  .hr {
    height: 16px;
    background-color: #f7f7f7;
  }
}
.search-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px !important;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.product {
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
}
.table-tag {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
