export function showUrl(url, optString) {
  if (url) {
    const baseUrl = formateAliOOSUrl(url, optString);
    return baseUrl;
  }
  return '';
}
function formateAliOOSUrl(oosUrl, option = '') {
  const urlArr = oosUrl.split('?');
  const baseUrl = urlArr[0];
  const url = baseUrl + '?' + option;
  return url;
}
